import Vue from 'vue'
import VueRouter from 'vue-router'
// 导入页面
import Login from '../components/Login.vue'
import Home from '../components/Home.vue'
import Index from '../components/Index.vue'
import News from '../components/news/NewsList.vue'
import AddNews from '../components/news/AddNews.vue'
import EditNews from '../components/news/EditNews.vue'
import NewsCategory from '../components/news/NewsCategory.vue'
import UserList from '../components/user/UserList.vue'
import Keshi from '../components/keshi/Keshi.vue'
import Yisheng from '../components/keshi/Yisheng.vue'
import Guahao from '../components/guahao/Guahao.vue'
import GuahaoSet from '../components/guahao/GuahaoSet.vue'
import EditBaogao from '../components/guahao/EditBaogao.vue'
import AddBaogao from '../components/guahao/AddBaogao.vue'
import Baogao from '../components/guahao/Baogao.vue'
import Jiuzhenren from '../components/guahao/Jiuzhenren.vue'

Vue.use(VueRouter)

// redirect 路由重定向

const routes = [
  { path: '/', redirect: '/home' },
  { path: '/login', component: Login },
  {
    path: '/home',
    component: Home,
    redirect: '/guahao', // 重定向
    children: [
      // main主题的子路由全部放这里
      { path: '/index', component: Index },
      { path: '/keshi', component: Keshi },
      { path: '/yisheng', component: Yisheng },
      { path: '/news', component: News },
      { path: '/add_news', component: AddNews },
      { path: '/edit_news', component: EditNews },
      { path: '/category_news', component: NewsCategory },
      { path: '/guahao', component: Guahao },
      { path: '/guahao_set', component: GuahaoSet },
      { path: '/edit_baogao', component: EditBaogao },
      { path: '/add_baogao', component: AddBaogao },
      { path: '/baogao', component: Baogao },
      { path: '/jiuzhenren', component: Jiuzhenren },
      { path: '/user', component: UserList },
    ]
  }
]

const router = new VueRouter({
  routes
})

// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
  // to 将要访问的路径
  // from 代表从那个路劲跳转而来
  // next 是一个函数，表示放行
  // next() 放行， next('/login') 强制跳转
  if (to.path === '/login') return next()
  // 获取token
  const tokenStr = window.sessionStorage.getItem('token')
  // 如果token不存在，直接跳转到登录页面
  if (!tokenStr) return next('/login')
  // 否则继续执行

  next()
})

export default router
