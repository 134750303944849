<template>
	<div class="ArticleList">
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>挂号管理</el-breadcrumb-item>
			<el-breadcrumb-item>预约设置</el-breadcrumb-item>
		</el-breadcrumb>
		<el-card class="box-card">
      <el-row :gutter="20">
      	<el-col>
      		<el-button size="mini" type="success" @click="add.dialogVisible = true">添加时间段</el-button>
        </el-col>
      </el-row>
			<el-table :data="listData" border style="width: 100%">
				<el-table-column prop="start_time" label="开始时间">
				</el-table-column>
				<el-table-column prop="end_time" label="结束时间">
				</el-table-column>
        <el-table-column prop="nums" label="允许预约人数" width="150">
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <!-- 修改 -->
            <el-button type="primary" icon="el-icon-setting" size="mini"
              @click="editData(scope.row.id)"
            ></el-button>
            <!-- 删除 -->
            <el-button
            type="primary"
            size="mini"
            icon="el-icon-delete"
            @click="delData(scope.row.id)"></el-button>
          </template>

        </el-table-column>
			</el-table>
		</el-card>
    <!-- 添加 -->
    <el-dialog
      title="添加预约时间段"
      :visible.sync="add.dialogVisible"
      width="48%"
      >
      <el-form label-width="100px" :model="add.dataForm" :rules="add.rules" ref="add.dataForm">
        <el-form-item label="预约时间段">
          <el-time-select
            style="margin-right: 20px;"
            placeholder="起始时间"
            v-model="add.dataForm.start_time"
            :picker-options="{
              start: '08:00',
              step: '00:30',
              end: '18:00'
            }">
          </el-time-select>
          <el-time-select
            placeholder="结束时间"
            v-model="add.dataForm.end_time"
            :picker-options="{
              start: '08:00',
              step: '00:30',
              end: '18:00',
              minTime: add.dataForm.start_time
            }">
          </el-time-select>
        </el-form-item>
        <el-form-item label="预约人数">
          <el-input  v-model="add.dataForm.nums" maxlength="2"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="add.dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addDataFunc('add.dataForm')">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑 -->
    <el-dialog
      title="编辑预约时间段"
      :visible.sync="edit.dialogVisible"
      width="48%"
      >
      <el-form label-width="100px" :model="edit.dataForm" :rules="edit.rules" ref="edit.dataForm">
        <el-form-item label="预约时间段">
          <el-time-select
            style="margin-right: 20px;"
            placeholder="起始时间"
            v-model="edit.dataForm.start_time"
            :picker-options="{
              start: '08:00',
              step: '00:30',
              end: '18:00'
            }">
          </el-time-select>
          <el-time-select
            placeholder="结束时间"
            v-model="edit.dataForm.end_time"
            :picker-options="{
              start: '08:00',
              step: '00:30',
              end: '18:00',
              minTime: edit.dataForm.start_time
            }">
          </el-time-select>
        </el-form-item>
        <el-form-item label="预约人数">
          <el-input  v-model="edit.dataForm.nums" maxlength="2"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="edit.dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editDataFunc('edit.dataForm')">确 定</el-button>
      </span>
    </el-dialog>
	</div>
</template>

<script>
export default {
  name: 'ArticleList',
  data () {
    return {
      listData: [],
      add:{
        dialogVisible: false,
        dataForm:{}
      },
      edit:{
        dialogVisible: false,
        dataForm:{}
      }
    }
  },
  created () {
    this.getList()
  },
  methods: {
    async getList () {
      const {
        data: res
      } = await this.$http.get(
        'api/admin/guahaoset/list'

      )
      console.log('res',res)
      this.listData = res.data
      console.log(this.listData)
    },
    async addDataFunc(){
      let params = this.add.dataForm
      if (!params.start_time) {
        return this.$message.error('起始时间不能为空')
      }
      if (!params.end_time) {
        return this.$message.error('结束时间不能为空')
      }
      if (!params.nums) {
        return this.$message.error('预约人数不能为空')
      }
      const {
      	data: res
      } = await this.$http.post(
          'api/admin/guahaoset/add',
      		this.add.dataForm
      )
      if (res.status) return this.$message.error(res.message)
      console.log('添加返回结果', res)
      this.$message.success(res.message)
      this.add.dialogVisible = false
      this.getList()
      this.add.dataForm = {}
    },
    // 获取单条数据
    async editData(id) {
      console.log('id:', this.id)
      const {
        data: res
      } = await this.$http.get(
        'api/admin/guahaoset/get', {
          params: {
            id: id,
          }
        }
      )
      this.edit.dialogVisible = true
      console.log('res', res)
      this.edit.dataForm = res.data
      console.log('this.editDataForm', this.edit.dataForm)
    },
    // 编辑
    async editDataFunc(id) {
      const {
        data: res
      } = await this.$http.post(
          'api/admin/guahaoset/edit',
          this.edit.dataForm
      )
      console.log('res结果：', res)
      if (res.status) return this.$message.error(res.message)

      this.$message.success(res.message)
      this.edit.dialogVisible = false
      this.getList()
    },
    // 删除
    async delData(id) {
      const {
        data: res
      } = await this.$http.post(
        'api/admin/guahaoset/del',{
          'id':id
        }
      )
      if (res.status) return this.$message.error(res.message)
      this.$message.success(res.message)
      this.getList()
    }

  }
}
</script>
<style>
</style>
