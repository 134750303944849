<template>
	<div class="ArticleList">
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>系统设置</el-breadcrumb-item>
			<el-breadcrumb-item>管理员列表</el-breadcrumb-item>
		</el-breadcrumb>
		<el-card class="box-card">
			<el-row :gutter="20">
				<el-col>
					<el-button size="mini" type="success" @click="add.dialogVisible = true">添加管理员</el-button>
        </el-col>
			</el-row>
      <!-- 用户列表 -->
			<el-table :data="listData" border style="width: 100%">
				<el-table-column prop="id" label="编号" width="150">
				</el-table-column>
				<el-table-column prop="admin_name" label="账号" width="100">
				</el-table-column>
        <el-table-column prop="password" label="密码" width="180">
        </el-table-column>
				<el-table-column prop="keshi" label="科室">
				</el-table-column>
        </el-table-column>
				<el-table-column label="操作">
          <template slot-scope="scope">
            <!-- 修改 -->
           <el-button type="primary" icon="el-icon-setting" size="mini"
              @click="editFunc(scope.row.id)"
            ></el-button>
            <!-- 删除 -->
            <el-button v-if="scope.row.is_manage==0" type="primary" size="mini" icon="el-icon-delete" @click="delFunc(scope.row.id)"></el-button>
          </template>

				</el-table-column>
			</el-table>
      <!-- 用户列表分页 -->
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="currentPage4" :page-sizes="[10, 20, 30, 50]" :page-size="pageSize"
				layout="total, sizes, prev, pager, next, jumper" :total="total">
			</el-pagination>
		</el-card>

    <!-- 添加用户 -->
    <el-dialog
      title="添加管理员"
      :visible.sync="add.dialogVisible"
      width="30%"
      >
      <el-form label-width="70px" :model="add.dataForm" ref="add.dataForm">
        <el-form-item label="账号">
          <el-input  v-model="add.dataForm.admin_name"></el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input v-model="add.dataForm.password"></el-input>
        </el-form-item>
        <el-form-item label="科室">
          <el-checkbox-group v-model="checkedValue"
         >
            <el-checkbox
            @change="handleCheckedChange"
            v-for="item in keshi"
            :label="item.value"
            :key="item.value">
              {{item.value}}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="add.dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addFunc('add.dataForm')">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 编辑用户 -->
    <el-dialog
      title="编辑管理员"
      :visible.sync="edit.dialogVisible"
      width="30%"
      >
      <el-form label-width="70px" :model="add.dataForm" ref="add.dataForm">
        <el-form-item label="账号">
          <el-input  v-model="edit.dataForm.admin_name"></el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input v-model="edit.dataForm.password"></el-input>
        </el-form-item>
        <el-form-item label="科室">
          <el-checkbox-group v-model="edit.dataForm.checkedValue"
         >
            <el-checkbox
            @change="handleCheckedChange"
            v-for="item in keshi"
            :label="item.value"
            :key="item.value">
              {{item.value}}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="edit.dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editFuncDo('edit.dataForm')">确 定</el-button>
      </span>
    </el-dialog>

	</div>
</template>

<script>
	export default {
		data() {
			return {
        checkedValue: [],
        add: {
          dialogVisible: false,
          dataForm: {},
        },
        edit: {
          dialogVisible: false,
          dataForm: {},
        },
        status: true,
        dialogVisible: false,
        rechangeLog: false,
				listData: [],
				total:100,
				pageSize:10,
				currentPage4:1,
				params:{
					page: 1,
					limit: 10
				},
        keshi:[]
			}
		},
		created() {
			this.getArticleList()
      this.getData()
		},
		methods: {
      async getData(){
        const {
        	data: res
        } = await this.$http.get(
        	'/guahao_get_data'
        )

        this.keshi = res.data.keshi
        console.log('this.keshi', this.keshi)
      },
      handleCheckedChange(value){
        console.log('选中值:', this.checkedValue)

      },
			async getArticleList() {
				const {
					data: res
				} = await this.$http.get(
					'api/admin/user/list', {
						params: this.params
					}
				)
				this.listData = res.data.data
				this.total = res.data.total
				console.log(this.listData)
			}, //每页显示多少条
			handleSizeChange(newSize){
				console.log(newSize)
				this.params.limit = newSize
				this.getArticleList()
			},//第几页
			handleCurrentChange(newPage){
				console.log(newPage)
				this.params.page = newPage
				this.getArticleList()
			},
      // 添加用户
      async addFunc(formName) {
        let params = this.add.dataForm
        if (!params.admin_name) {
          return this.$message.error('账号不能为空')
        }
        if (!params.password) {
          return this.$message.error('密码不能为空')
        }
        if (!this.checkedValue.length) {
          return this.$message.error('科室必须选择')
        }
        this.add.dataForm.checkedValue = this.checkedValue
        console.log('this.add.dataForm', this.add.dataForm)
        const {
        	data: res
        } = await this.$http.post(
            'api/admin/user/add',
        		this.add.dataForm
        )
        if (res.status) return this.$message.error(res.message)
        console.log('添加用户返回结果', res)
        this.$message.success(res.message)
        this.add.dialogVisible = false
        this.add.dataForm = {}
        this.getArticleList()
      },
      // 删除用户
      async delFunc(id) {
        const {
          data: res
        } = await this.$http.post(
          'api/admin/user/del',{
            'id':id
          }
        )
        if (res.status) return this.$message.error(res.message)
        this.$message.success(res.message)
        this.getArticleList()
      },
      // 修改
      async editFunc(id){
        const {
          data: res
        } = await this.$http.get(
          'api/admin/user/get', {
            params: {
              id: id,
            }
          }
        )
        this.edit.dataForm = res.data
        this.edit.dialogVisible = true
      },
      // 编辑操作
      async editFuncDo(id) {
        let params = this.edit.dataForm
        if (!params.admin_name) {
          return this.$message.error('账号不能为空')
        }
        if (!params.password) {
          return this.$message.error('密码不能为空')
        }
        if (!params.checkedValue.length) {
          return this.$message.error('科室必须选择')
        }
        const {
          data: res
        } = await this.$http.post(
          'api/admin/user/edit',
          params
        )
        if (res.status) return this.$message.error(res.message)
        this.$message.success(res.message)
        this.getArticleList()
        this.edit.dialogVisible = false
      },
		}
	}
</script>
<style>
</style>
